import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLectures(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lectures', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourseLectures(ctx, queryParams) {
      console.log(queryParams)
      return new Promise((resolve, reject) => {
        axios
          .get('/lectures', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLecture(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lectures/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLecture(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
            .put('/lectures/' + id, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addLecture(ctx, lectureData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/course-lectures', { lectureData })
          .then(response => resolve(response))
          .catch(error => reject(error))

      })
    },
    deleteLecture(ctx, lectureId) {
      return new Promise((resolve, reject) => {
        axios
            .delete('/lectures/' + lectureId)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
