<template>
  <b-sidebar
      id="add-new-country-sidebar"
      :visible="isAddNewCategorySidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-country-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Category
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <b-card-code>
            <!-- Title En -->
            <validation-provider
                #default="validationContext"
                name="Title En"
                rules="required"
            >
              <b-form-group
                  label="Title En"
                  label-for="title_en"
              >
                <b-form-input
                    id="title_en"
                    v-model="categoryData.title_en"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Country Title En"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title Ar -->
            <b-form-group
                label="Title Ar"
                label-for="title_ar"
            >
              <b-form-input
                  id="title_ar"
                  v-model="categoryData.title_ar"
                  autofocus
                  trim
                  placeholder="Country Title Ar"
              />
            </b-form-group>

          </b-card-code>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Add
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BFormCheckbox, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BCardCode,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCategorySidebarActive',
    event: 'update:is-add-new-country-sidebar-active',
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      requireImageFlag: false
    }
  },
  setup(props, {emit}) {
    const blankCategoryData = {
      title_en: '',
      title_ar: ''
    }
    const countryFlagId = ref(null);
    const categoryData = ref(JSON.parse(JSON.stringify(blankCategoryData)))
    const resetCategoryData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategoryData))
    }

    const onSubmit = () => {
      // if (categoryData.flag != null){
      let data = {
        title_en: categoryData.value.title_en,
        title_ar: categoryData.value.title_ar,
        course_id: router.currentRoute.params.id
      }
      store.dispatch('courses/addCategory', {data})
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-category-sidebar-active', false)
          })
      // }

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCategoryData)

    return {
      categoryData,
      onSubmit,
      countryFlagId,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
