import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFlashCards(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/flash-cards', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourseFlashCards(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/flash-cards', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFlashCard(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/flash-cards/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFlashCard(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
            .put('/flash-cards/' + id, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addFlashCard(ctx, flashCardData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/flash-cards',  flashCardData )
          .then(response => resolve(response))
          .catch(error => reject(error))

      })
    },
    deleteFlashCard(ctx, flashCardId) {
      return new Promise((resolve, reject) => {
        axios
            .delete('/flash-cards/' + flashCardId)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
