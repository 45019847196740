import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/courses/', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReviews(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/course-reviews/${queryParams.id}`, { params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/course-categories/${queryParams.id}`, { params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategoriesSideMenu(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/all-categories`, { params: queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchSubCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/course-categories/${queryParams.id}`, { params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
            .put('/categories/' + id, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addCategory(ctx, {data}) {
      return new Promise((resolve, reject) => {
        axios
            .post('/categories/'+data.course_id, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    updateCourse(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
            .put('/courses/' + id, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCourse(ctx, courseId) {
      return new Promise((resolve, reject) => {
        axios
            .delete('/courses/' + courseId)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    deleteCourseReview(ctx, courseReviewId) {
      return new Promise((resolve, reject) => {
        axios
            .delete('/course-reviews/' + courseReviewId)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    deleteCourseCategory(ctx, courseCategoryId) {
      return new Promise((resolve, reject) => {
        axios
            .delete('/categories/' + courseCategoryId)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
